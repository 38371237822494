import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Header.css';

const Header = () => {
  return (
    <header className="header center">
      <nav className="nav">
        <div className="logo">
          <Link to="/">
            <img id='favicon' src="/images/fav-da.png" alt="Logo" />
            <img className='hide-on-laptop' src="/images/devsaura2.png" alt="Logo" />
          </Link>
        </div>
        <div>
          <h1 className='hide-on-phone'>Academy</h1>
        </div>
        <div className="links">
          <a className="nav-link" href='https://devsaura.com/contact-us' target='_blank' rel='noreferrer noopener'>Contact</a>
        </div>
      </nav>
    </header>
  );
}

export default Header;
